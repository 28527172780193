<template>
  <div class="order-gift-operation" v-loading="loading">
    <co-fold-block title="基本信息">
      <el-form ref="basic" :model="formModel" :rules="formRules" label-width="130px" label-position="left">
        <el-form-item label="活动名称" prop="activity_title">
          <el-input v-model="formModel.activity_title" />
        </el-form-item>
        <el-form-item label="活动时间" prop="active_time">
          <el-date-picker
            v-model="formModel.active_time"
            type="datetimerange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="引导注册渠道" prop="register_source_id">
          <div class="channel-select">
            <el-select v-model="formModel.register_source_id" filterable placeholder="请输入或选择">
              <el-option
                v-for="channel in channelOptions"
                :key="channel.channel_id"
                :label="channel.channel_name"
                :value="channel.channel_id"
              >
              </el-option>
            </el-select>
            <el-popover
              placement="bottom-start"
              width="350"
              v-model="channelVisible">
              <div style="display: flex;align-items: center">
                <div style="margin-right: 8px;">注册渠道名称：</div>
                <div><el-input v-model="channel_name" /></div>
              </div>
              <div style="text-align: right; margin-top: 12px;">
                <el-button size="mini" @click="channelVisible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="handleAddChannel">提交</el-button>
              </div>
              <el-button slot="reference" type="text" style="margin-left: 8px;">新增渠道</el-button>
            </el-popover>
          </div>
        </el-form-item>
        <el-form-item label="订单校验模版" prop="activity_template">
          <el-button type="text" @click="handleCheckTemplate">{{ formModel.activity_template ? '查看模版' : '设置模版'}}</el-button>
        </el-form-item>
      </el-form>
    </co-fold-block>
    <co-fold-block title="会员权益可发放订单条件设置">
      <el-form ref="condition" :model="formModel" :rules="formRules" label-width="130px" label-position="left">
        <el-form-item label="可领取下单时间" prop="order_time">
          <el-date-picker
            v-model="formModel.order_time"
            type="datetimerange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <div class="form-item-desc">指定时间内下单的平台订单适用</div>
        <el-form-item label="可领取下单平台" prop="source">
          <el-checkbox-group v-model="formModel.source">
            <el-checkbox v-for="item in platLists" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="可领取订单门店" prop="order_shop">
          <div class="tip-color">平台订单的履约门店需在指定门店中方可领取，不传门店即为不限门店，活动保存后在列表中上传门店</div>
        </el-form-item>
        <el-form-item label="可领取订单商品" prop="item_ids">
          <OrderGoods v-model="formModel.item_ids" />
          <div class="tip-color">平台订单中适用赠券的商品，按照订单中购买的赠券商品的数量赠券</div>
        </el-form-item>
      </el-form>
    </co-fold-block>
    <co-fold-block title="会员权益设置">
      <el-form ref="equity" :model="formModel" :rules="formRules" label-width="120px" label-position="left">
        <el-form-item label="权益类型" prop="interests_type">
          <el-radio-group v-model="formModel.interests_type">
            <el-radio-button :label="1">优惠券</el-radio-button>
            <el-radio-button :label="2">额外保修</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="onlyShowWarranty" label="保修服务名称" prop="service_name">
          <el-input v-model="formModel.service_name" />
        </el-form-item>
        <el-form-item label="领取成功图" prop="received_image.imgUrl">
          <div style="display: flex;">
            <SpImage :src="formModel.received_image.imgUrl" width="120" height="120" style="margin-right: 24px;"/>
            <AttrHotSetting v-model="formModel.received_image" :key="formModel.received_image.imgUrl" />
          </div>
        </el-form-item>
        <el-form-item v-if="onlyShowCoupon" label="发放时间" prop="send_day">
          <div style="display: flex;">
            <span class="mr-12">订单完成</span>
            <el-input-number
              v-model="formModel.send_day"
              :controls="false"
              :min="0"
              :precision="0"
              placeholder="请输入"
            />
            <span class="ml-12">天后发放</span>
          </div>
        </el-form-item>
        <el-form-item v-if="onlyShowCoupon" label="选择优惠券" prop="coupon">
          <Coupon v-model="formModel.coupon"/>
        </el-form-item>
        <el-form-item v-if="onlyShowCoupon" label="可领取时效" prop="receive_term">
          <div style="display: flex;">
            <span class="mr-12">订单签收/核销后</span>
            <el-input-number
              v-model="formModel.receive_term"
              :controls="false"
              :min="0"
              :precision="0"
              placeholder="请输入"
            />
            <span class="ml-12">天内可领取</span>
          </div>
        </el-form-item>
        <el-form-item v-if="onlyShowWarranty" label="绑定成功图片" prop="bind_succ_img">
          <div style="display: flex;">
            <SpImage :src="formModel.bind_succ_img.imgUrl" width="120" height="120" style="margin-right: 24px;"/>
            <AttrHotSetting v-model="formModel.bind_succ_img" :key="formModel.bind_succ_img.imgUrl" />
          </div>
        </el-form-item>
        <el-form-item v-if="onlyShowWarranty" label="服务评价" prop="service_assessments">
          <el-input :value="formModel.associated_questionnaire_name" placeholder="请选择" clearable @focus="handleSelectServiceAss" @clear="handleClearServiceAss"/>
        </el-form-item>
        <el-form-item v-if="onlyShowWarranty && formModel.associated_questionnaire" label="可评价时效" prop="evaluate_term" >
          <div style="display: flex;">
            <span class="mr-12">取机后</span>
            <el-input-number
              v-model="formModel.evaluate_term"
              :controls="false"
              :min="0"
              :precision="0"
              placeholder="请输入"
            />
            <span class="ml-12">天内可评价</span>
          </div>
        </el-form-item>
        <el-form-item v-if="onlyShowWarranty" label="保修有效期" prop="maintenance_day">
          <div style="display: flex;">
            <span class="mr-12">绑定后</span>
            <el-input-number
              v-model="formModel.maintenance_day"
              :controls="false"
              :min="0"
              :precision="0"
              placeholder="请输入"
            />
            <span class="ml-12">天内有效</span>
          </div>
        </el-form-item>
        <el-form-item v-if="onlyShowWarranty" label="保修可绑定时效" prop="maintenance_term">
          <div style="display: flex;">
            <span class="mr-12">订单签收/核销后</span>
            <el-input-number
              v-model="formModel.maintenance_term"
              :controls="false"
              :min="0"
              :precision="0"
              placeholder="请输入"
            />
            <span class="ml-12">天内有效</span>
          </div>
        </el-form-item>
      </el-form>
    </co-fold-block>
    <FooterSubmitButtons @cancel="hanldeCancel" @submit="handleSubmit"/>
    <QuestionnaireSelect :visible="questionVisible" :radioId="formModel.associated_questionnaire" @saveAction="saveAction" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { create, update, detail, createChannel, getChannelList } from './api'
import { mapGetters } from 'vuex'
import FooterSubmitButtons from './component/footer-submit-buttons'
import OrderGoods from './component/order-goods'
import Coupon from './component/coupon'
import AttrHotSetting from "@/view/decorate/wgts/wgt_hotzone/attr-hotsetting";
import QuestionnaireSelect from '@/components/function/questionnaireSelect'
import { useDialog } from '@shopex/finder'

export default {
  components: { FooterSubmitButtons, OrderGoods, Coupon, AttrHotSetting, QuestionnaireSelect },
  data () {
    return {
      loading: false,
      id: null,
      channelVisible: false,
      channel_name: '',
      platLists: [
        { label: '京东到家', value: 'jd' },
        { label: '京东自营', value: 'jdzy' },
        { label: '美团闪购', value: 'mt' },
        { label: '微信小程序', value: 'wxapp' },
        { label: '淘宝', value: 'tb' },
        { label: '抖音', value: 'dy' }
      ],
      formModel: {
        activity_title: '',
        active_time: [],
        register_source_id: '',
        order_time: [],
        source: [],
        item_ids: [],
        activity_template: {},
        interests_type: 1,
        service_name: '',
        received_image: {
          imgUrl: '',
          data: []
        },
        bind_succ_img: {
          imgUrl: '',
          data: []
        },
        associated_questionnaire: '',
        associated_questionnaire_name: '',
        send_day: 7,
        coupon: [],
        receive_term: 0,
        evaluate_term: 30,
        maintenance_day: 0,
        maintenance_term: 14,
      },
      formRules: {
        activity_title: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        active_time: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        activity_template: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        order_time: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        source: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        item_ids: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        service_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        'received_image.imgUrl': [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        bind_succ_img: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        send_day: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        coupon: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        receive_term: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        evaluate_term: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        maintenance_day: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        maintenance_term: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
      },
      channelOptions: [],
      questionVisible: false,
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'wxapp_id',
      'template_name'
    ]),
    onlyShowCoupon() {
      return this.formModel.interests_type === 1
    },
    onlyShowWarranty() {
      return this.formModel.interests_type === 2
    },
  },
  async mounted() {
    this.getChannel()
    const id = this.$route.query.id
    if(id) {
      this.id = id
      this.initDetail()
    }
  },
  methods: {
    async initDetail() {
      try {
        this.loading = true
        const res = await detail(this.id)
        let { data } = res.data
        data = this.formatTimeBy1000('up', data)
        this.formModel = Object.assign(this.formModel, data)
        this.formModel.active_time = [data.activity_begin_time, data.activity_end_time]
        this.formModel.order_time = [data.order_begin_time, data.order_end_time]
        this.formModel.item_ids = data.items.map(item => ({
          item_id: item.item_id,
          item_name: item.item_name,
          isValidSwitch: item.isValidSwitch
        }))
        this.formModel.activity_template = JSON.parse(data.activity_template)
        const { received_image, card_list, bind_succ_img } = data.gifts[0].interests_info
        this.formModel = Object.assign(this.formModel, data.gifts[0].interests_info)
        if(received_image) {
          this.formModel.received_image = received_image
        }
        if(bind_succ_img) {
          this.formModel.bind_succ_img = bind_succ_img
        }
        this.formModel.coupon = [...card_list]
      } catch (e) {
        console.log("🚀🚀🚀🚀🚀🚀 ~ initDetail ~ e:", e)
      } finally {
        this.loading = false
      }
    },
    /** 新增渠道 */
    async handleAddChannel() {
      if(!this.channel_name) return
      await createChannel(this.channel_name)
      this.$message.success('添加成功')
      this.getChannel()
      this.channel_name = ''
      this.channelVisible = false
    },
    async getChannel() {
      const res = await getChannelList()
      const { data: { list } }  = res.data
      this.channelOptions = list
    },
    handleSelectServiceAss() {
      this.questionVisible = true
    },
    handleClearServiceAss() {
      this.formModel.associated_questionnaire = ''
      this.formModel.associated_questionnaire_name = ''
    },
    /** 订单校验模版 */
    handleCheckTemplate() {
      useDialog(null, {
        title: `订单校验模板`,
        width: '80%',
        activity_template: this.formModel.activity_template,
        component: () => import('./component/check-template.vue'),
        actions:  [
          { label: '取消', key: 'close', size: 'default' },
          { label: '确定', key: 'save', type: 'primary', size: 'default' }
        ]
      }).then(arg => {
        if(arg) {
          this.formModel.activity_template = arg
        }
      })
    },
    // 调查问卷选择结果
    saveAction (data) {
      this.closeDialog()
      if (!data?.length) return
      this.formModel.associated_questionnaire = data[0].id
      this.formModel.associated_questionnaire_name = data[0].tpl_name
    },
    closeDialog () {
      this.questionVisible = false
    },
    hanldeCancel() {
      this.$router.go(-1)
    },
    /**
     * @desc 根据时间戳格式化到s
     * @param {String} type up *1000 |  down /1000
     * @param {Object} data
    */
    formatTimeBy1000(type, obj) {
      switch (type) {
        case 'up':
          if(obj.activity_begin_time) obj.activity_begin_time = Number(obj.activity_begin_time) * 1000
          if(obj.activity_end_time) obj.activity_end_time = Number(obj.activity_end_time) * 1000
          if(obj.order_begin_time) obj.order_begin_time = Number(obj.order_begin_time) * 1000
          if(obj.order_end_time) obj.order_end_time = Number(obj.order_end_time) * 1000
          return obj
        case 'down':
          if(obj.activity_begin_time) obj.activity_begin_time = Number(obj.activity_begin_time) / 1000
          if(obj.activity_end_time) obj.activity_end_time = Number(obj.activity_end_time) / 1000
          if(obj.order_begin_time) obj.order_begin_time = Number(obj.order_begin_time)  / 1000
          if(obj.order_end_time) obj.order_end_time = Number(obj.order_end_time)  / 1000
          return obj
      }
    },
    async handleSubmit() {
      try {
        await this.$refs.basic.validate()
        await this.$refs.condition.validate()
        await this.$refs.equity.validate()
        const { active_time, order_time, coupon, item_ids, received_image, bind_succ_img, activity_template } = this.formModel
        let data = {
          ...this.formModel,
          activity_begin_time: active_time[0],
          activity_end_time: active_time[1],
          order_begin_time: order_time[0],
          order_end_time: order_time[1],
          activity_template: JSON.stringify(activity_template),
          received_image: JSON.stringify(received_image),
          bind_succ_img: bind_succ_img ? JSON.stringify(bind_succ_img) : '',
          card_id: coupon?.map(item => item.card_id).join(),
          item_ids: item_ids.map(item => (item.item_id + '_' + item.isValidSwitch))
        }
        data = this.formatTimeBy1000('down', data)
        // 删除无用字段
        delete data.coupon
        delete data.gifts
        delete data.items
        delete data.card_list

        this.loading = true
        if(this.id) {
          data.id = this.id
          await update(data)
        } else {
          await create(data)
        }
        this.$message.success('保存成功')
        this.hanldeCancel()
      } finally{
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.order-gift-operation {
  position: relative;
  ::v-deep .el-input {
    width: 250px;
  }
  ::v-deep .el-input-number {
    width: 100px;
    .el-input {
      width: 100px;
    }
  }
  .ml-12 {
    margin-left: 12px;
  }
  .mr-12 {
    margin-right: 12px;
  }
  .channel-select {
    display: flex;
  }
  .form-item-desc {
    margin-left: 120px;
    font-size: 12px;
    color: #909399;
  }
  .tip-color {
    color: #909399;
  }
  .temp-content {
    display: flex;
    flex-wrap: nowrap;
    .cell-start {
      color: #F56C6C;
    }
    &-left {
      width: 35%;
      min-width: 450px;
    }
    &-right {
      width: 35%;
      min-width: 450px;
      margin-left: 24px;
    }
    &-wrapper {
      border: 1px solid #e7e7eb;
      margin: 24px 0;
      &-title {
        padding: 0 12px;
        background-color: #edfefc;
        border-bottom: 1px solid #e7e7eb;
      }
      &-content {
        height: 200px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        .el-form-item {
          margin: 10px 0;
        }
        ::v-deep .el-form-item__label:before {
          display: none;
        }
      }
      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e7e7eb;
        padding: 0 12px;
        background-color: #edfefc;
        .set-name {
          width: calc(100% - 40px - 24px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .set-path {
          color: #409eff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
