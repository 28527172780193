import { createSetting } from '@shopex/finder'

export const tableSetting = (vm) => createSetting({
  search: [],
  columns: [
    { name: '券ID', key: 'card_id', minWidth: 150 },
    { name: '券名称', key: 'title', minWidth: 150 },
    { name: '券类型',
      key: 'card_type',
      minWidth: 120,
      formatter: v => vm.$options.filters['formatCardStr'].call(vm, v)
    },
    { name: '卡券有效期',
      key: 'time',
      minWidth: 200,
      render: (h, { row }) => {
        if(row.takeEffect) return row.takeEffect
        if(row.begin_time) {
          if(row.end_time) {
            return `${vm.$options.filters['datetime'].call(vm, row.begin_time)} ~ ${vm.$options.filters['datetime'].call(vm, row.end_time)}`
          }
          return vm.$options.filters['datetime'].call(vm, row.begin_time)
        }
      }
    },
  ],
  actions: [
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          vm.handleDelete(rows)
        }
      }
    },
  ]
})
